.spacer {
    padding: 17.5%;
    margin-top: 20%;
    padding-left: 0%;
    padding-right: 0%;
    @media (min-width: $desktop-breakpoint) {
        padding-top: 2.5%;
        padding-bottom: 2.5%;
    }
}

.spacer--welcome {
    margin-top: 0;
}