.text-input {
    border: 1px solid #cacccd;
    font-size: $font-size-large;
    font-weight: 300;
    height: 50px;
    padding: $s-size;
}

.select {
    @extend .text-input;
}

.textarea {
    @extend .text-input;
    height: 10rem;
}