.header {
    background: $off-black;
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 1;
}

.header__content {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: $s-size 0;
}

.header__title {
    color: $blue;
    text-decoration: none;
    h1 {
        margin: 0;
    }
}

.header__dropdown {
    
}