.button {
    background: $purple;
    border: none;
    border-bottom: .6rem solid darken($purple, 10%);
    color: $off-white;
    display: inline-block;
    font-size: $font-size-large;
    font-weight: 300;
    line-height: 1;
    margin-bottom: $m-size;
    padding: $s-size;
}

.button--modalgo {
    margin-right: $s-size;
    background: $purple;
    border-bottom: .6rem solid darken($purple, 10%);
}

.button--secondary {
    background: #888888;
    border-bottom: .6rem solid darken(#888888, 10%);
}

.button--source {
    background: $green;
    border-bottom: .6rem solid darken($green, 10%);
}

.button--welcome {
    background: $blue;
    border-bottom: .6rem solid darken($blue, 10%);
}

.button--aboutme {
    background: $orange;
    border-bottom: .6rem solid darken($orange, 10%);
}

.button--projects {
    background: $purple;
    margin-top: $m-size;
    border-bottom: .6rem solid darken($purple, 10%);
    @media (min-width: $desktop-breakpoint) {
        margin-top: 0;
    }
}

.button--resume {
    background: $green;
    margin-top: $m-size;
    border-bottom: .6rem solid darken($green, 10%);
    @media (min-width: $desktop-breakpoint) {
        margin-top: 0;
    }
}

.button--contactme {
    background: $red;
    border-bottom: .6rem solid darken($red, 10%);
}

.button__link {
    background: none;
    border: none;
    color: white;
    display: inline-block;
    font-size: $font-size-large;
    font-weight: 300;
    line-height: 1;
    padding: $s-size;
    font-weight: bold;
    &:hover {
        text-decoration: underline;
    }
}

.button__link--welcome {
    color: $blue;
}

.button__link--aboutme {
    color: $orange;
}

.button__link--projects {
    color: $purple;
}

.button__link--resume {
    color: $green;
}

.button__link--contactme {
    color: $red;
}

.button__link--navigate{
    background: $blue;
    border-bottom: .6rem solid darken($blue, 10%);
    font-weight: normal;
    &:hover {
        text-decoration: none;
    }
}

.button__group {
    display: flex;
    justify-content: space-between;
    @media (min-width: $desktop-breakpoint) {
        margin-left: 25%;
        margin-right: 25%;
    }
}

.button__mobileheader {
    background: $purple;
    border: none;
    border-bottom: .6rem solid darken($purple, 10%);
    color: $off-white;
    display: inline-block;
    font-size: $font-size-large;
    font-weight: 300;
    line-height: 1;
    margin-bottom: $m-size;
    padding: $s-size;
    width: 100%;
}

.button__mobileheader--aboutme {
    background: $orange;
    border-bottom: .6rem solid darken($orange, 10%);
}

.button__mobileheader--projects {
    background: $purple;
    border-bottom: .6rem solid darken($purple, 10%);
}

.button__mobileheader--resume {
    background: $green;
    border-bottom: .6rem solid darken($green, 10%);
}

.button__mobileheader--contactme {
    background: $red;
    border-bottom: .6rem solid darken($red, 10%);
}

